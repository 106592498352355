import { useOfferStore, usePageStore } from '@/src/common/zustand';

import {
  getCustomer,
  getMetadata,
  getMainSubInvoiceItems,
  getSelectedPaymentOption,
} from '../helpers';

import { operations as PaymentOperations } from '@/src/schemas/payments';
import { PurchaseError } from '../types';
import { SubmitOrderOptions } from '../submitOrder';
import { trackReferralInFirstPromoter } from '../helpers/trackReferralInFirstPromoter';
import { getStripeError } from '../helpers/getStripeError';

type PostStripeSubRequestBody =
  PaymentOperations['post-stripe-subscription']['requestBody']['content']['application/json'];

export type ISubscription = Omit<
  PostStripeSubRequestBody['subscription'],
  'metadata'
> & {
  metadata: Omit<
    PostStripeSubRequestBody['subscription']['metadata'],
    'purchase_hash'
  >;
};
type IPurchaseRequest = Omit<PostStripeSubRequestBody, 'subscription'> & {
  subscription: ISubscription;
};

type PostStripeSubResponse =
  PaymentOperations['post-stripe-subscription']['responses']['201']['content']['application/json'];

export const purchaseSubMain = async (
  reCaptchaScore: number,
  { forceAutomaticMode }: SubmitOrderOptions
): Promise<PostStripeSubResponse | PurchaseError> => {
  // Offer details from store
  const offer = useOfferStore.getState().offer!;
  const encryptedOffer = useOfferStore.getState().encryptedOffer!;

  const accountId = offer.account.id;
  const integrationId = offer.payment_integrations.find(
    (i) => i.system_name === 'Stripe'
  )!.id;
  const liveMode = offer.status === 'live';
  const currencyCode = offer?.currency?.code ?? 'USD';

  const customPaymentMethods = offer?.payment_methods?.map(
    (method) => method.type as string
  );

  const paymentOption = getSelectedPaymentOption();

  // Automatic tax enabled
  const mode = liveMode ? 'livemode' : 'testmode';
  const automaticTaxEnabled =
    offer.account.integrations.find((i) => i.system_name === 'Stripe')
      ?.options?.[`stripe_tax_${mode}`] ?? false;

  // Discount
  const discountCode = usePageStore.getState().discountCode;
  const discountEncrypted = usePageStore.getState().discount?.encrypted;

  // Subscription items
  const subscriptionItems = [
    {
      price:
        usePageStore
          .getState()
          .subscriptionAllItems.find(
            (item) => item.reference === 'main_product'
          )?.price ?? (paymentOption?.stripe_price_id as string),
    },
  ];

  // Invoice items
  const invoiceItems = getMainSubInvoiceItems();

  // Trial days
  const trialDays = paymentOption?.trial_days ?? 0;

  // Order bump
  const bumpSelected = usePageStore.getState().bumpSelected;

  // Customer
  const customer = getCustomer();

  // Metadata
  const metadata = getMetadata();

  // Get loading mode (custom vs. automatic)
  const paymentElementLoadingMode =
    usePageStore.getState().paymentElementLoadingMode;

  const loadingMode = !!forceAutomaticMode
    ? 'automatic'
    : paymentElementLoadingMode;

  // Assemble request body
  const purchaseRequestBody: IPurchaseRequest = {
    account_id: accountId,
    customer,
    integration_id: integrationId,
    ...(discountEncrypted && { discount: discountEncrypted }),
    live_mode: liveMode,
    offer: encryptedOffer,
    subscription: {
      automatic_tax: {
        enabled: automaticTaxEnabled,
      },
      ...(discountCode && { coupon: discountCode.toUpperCase() }),
      currency: currencyCode,
      items: subscriptionItems,
      ...(invoiceItems.length && {
        add_invoice_items: invoiceItems,
      }),
      payment_behavior: 'default_incomplete',
      payment_settings: {
        save_default_payment_method: 'on_subscription',
        ...(loadingMode === 'custom' && {
          payment_method_types: customPaymentMethods,
        }),
        ...(loadingMode === 'custom' &&
          customPaymentMethods.includes('us_bank_account') && {
            payment_method_options: {
              us_bank_account: {
                verification_method: 'instant',
                financial_connections: {
                  permissions: ['payment_method'],
                },
              },
            },
          }),
      },
      metadata: {
        ...metadata,
        type: bumpSelected ? 'main+bump' : 'main',
        recaptcha_score: reCaptchaScore,
      },
      trial_period_days: trialDays,
    },
    payment_option_id: paymentOption?.id,
  };

  // 11. Send request to Go through Next.js psproxy API
  const pathName = window.location.pathname.replace(new RegExp('/', 'g'), '-');
  const pageSlug = pathName.charAt(0) === '-' ? pathName.slice(1) : pathName;

  const res = await fetch(`/api/psproxy/${pageSlug}/stripe/sub/sub`, {
    method: 'POST',
    body: JSON.stringify(purchaseRequestBody),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!res.ok) {
    return await getStripeError(res);
  }

  // 12. Check if there's a FirstPromoter integration
  if (customer.email) {
    trackReferralInFirstPromoter(customer.email);
  }

  // 13. Return response
  return await res.json();
};
