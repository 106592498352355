import moment from 'moment';
// import {
//   DiscountType,
//   IDiscountData,
// } from '../../../modules/payment-page/models/elements/coupon';
import { components } from '@/src/schemas/offer';
import { Offer } from '@/src/pages/[host]/[uid]/[slug]';
import { IDiscountData } from '@/src/components/Checkout/Checkout.types';
import { DiscountType } from '@/src/components/Checkout/Checkout.enums';
import { CURRENCY_CODE } from '@/src/components/Checkout/Checkout.constants';

export const isSubscription = (
  type: components['schemas']['PaymentOption']['payment_type']
) => type === 'subscription';

export const isOtp = (
  type: components['schemas']['PaymentOption']['payment_type']
) => type === 'one_time_fee';

export const withDiscount = (
  discount: IDiscountData,
  amount: number,
  zeroDecimal?: boolean
) => {
  if (discount) {
    if (discount.discount_type === DiscountType.flat) {
      const subtotal =
        amount - Number(discount.discount) * (zeroDecimal ? 1 : 100);
      return subtotal > 0 ? subtotal : 0;
    } else {
      let subtotal = amount - (amount * Number(discount.discount)) / 100;
      return subtotal > 0 ? subtotal : 0;
    }
  }
  return amount;
};

export const getSubStartDate = (trial: number, language: string) => {
  moment().locale(language);
  return moment().add(trial, 'days').format('MMM D');
};

export const isZeroDecimalCurrency = (
  currency: Offer['currency']['symbol']
) => {
  if (!currency) return false;
  // TODO: add full list of zero desimal currencies, use code instead of symbol
  // ['BIF', 'CLP', 'DJF', 'GNF', 'JPY', 'KMF', 'KRW', 'MGA', 'PYG', 'RWF', 'UGX', 'VND', 'VUV', 'XAF', 'XOF', 'XPF']
  return ['¥'].includes(currency);
};

export const denominate =
  (currency: Offer['currency']['symbol']) => (price: number) => {
    const denominator = isZeroDecimalCurrency(currency) ? 1 : 100;
    return price / denominator;
  };

export const formatPrice = (
  price: number,
  forceTwoDecimals: boolean = false
) => {
  const minimumFractionDigits = forceTwoDecimals ? 2 : 0;
  return Intl.NumberFormat('en-US', {
    minimumFractionDigits,
    maximumFractionDigits: 2,
  }).format(price);
};

export const getMinUnitAmount = (
  currencyCode: Offer['currency']['code']
): number => {
  // TODO: The currency rates need to be updated manually
  // See if there's a way to automate this
  const minPrices: { [key: string]: number } = {
    USD: 100,
    EUR: 100,
    GBP: 80,
    CAD: 135,
    AUD: 152,
    NZD: 163,
    SEK: 110,
    JPY: 148,
    TRY: 3210,
  };

  return minPrices[currencyCode!] || minPrices['USD'];
};

export const isCouponApplicable = (
  discount: IDiscountData,
  unitAmount: number,
  zeroDecimal: boolean,
  currencyCode: Offer['currency']['code']
) => {
  // amount = unit amount of main product
  // for subscriptions we ignore the trial details (free trial, paid trial, no trial)
  const minAmount = getMinUnitAmount(currencyCode ?? CURRENCY_CODE.USD);

  return withDiscount(discount, unitAmount, zeroDecimal) >= minAmount;
};
