import { useOfferStore, usePageStore } from '@/src/common/zustand';
import { IPaymentIntent } from '../core/purchaseOtpMain';

type Description = IPaymentIntent['description'];

export const getDescription = (): Description => {
  const { offer_label: label, main_product_label: productName } =
    useOfferStore.getState().offer!;
  const bumpSelected = usePageStore.getState().bumpSelected;
  const bumpProductName =
    useOfferStore.getState().offer!.bumps[0]?.product?.name;

  if (!bumpSelected || !bumpProductName) {
    return `${label} / ${productName}`;
  }

  return `${label} / ${productName} + ${bumpProductName}`;
};
